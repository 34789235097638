
.wrap-div{
    width: 100vw;
    display: flex;
}
.video_engine_div{
    height: 100vh;
    width: 65%;
    /* background-color: red; */
}
.chat-div{
    height: 100vh;
    width: 35%;
}


.video_engine_div.fullwidth{
  width: 100%;
}
#video iframe {
    width: 100% !important;
    height: 100vh !important;
    border-width: 0px !important;
}


.big-circle {
    width: 5rem;
    height: 5rem;
    border: 3px solid #ffff;
    border-radius: 50%;
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;
    border-color: #fff transparent #fff #fff;
    animation: bigcircle 1.2s linear infinite;
  }
   
  @keyframes bigcircle {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader_div{
    background: #263040;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100vh;
  }

  .sidebar{
    /* display: none; */
    display: block;
    position: absolute;
    right: 0;
    top: 5%;
    color: white;
    cursor: pointer;
    background: #141414;
    padding: 7px 6px;
    width: 77px;
    border-radius: 6px 0px 0px 6px;
    box-shadow: 0px 2px 8px 4px rgb(0 0 0 / 25%), 0px 0px 0px 1px rgb(0 0 0 / 15%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video_ended{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 600;
    background: white;
  }

  .chat-div2{
    position: absolute;
    top: 0px;
    right: -110%;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .chat-div2.active2 {
    right: 0px;
    opacity: 1;
    z-index: 1;
    width: 400px;
  }
  .show_notification{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notification_dot{
    position: absolute;
    border-radius: 50%;
    background-color: rgb(79, 170, 245);
    width: 10px;
    height: 10px;
    left: 20px;
    top: -2px;
  }
  .notification_header{
    color: #003c89;
    margin-bottom: 10px;
    font-weight:bold;
    font-size: 17px;
    display: flex;
    align-items: center;
  }
  .notification_div{
    display: flex;
    flex-direction: column;
  }
  .notification_msg{
    color: black;
    font-size: 15px;
  }
  .msger-input:focus{
    outline: none !important;
    border:1px solid #333;
    border-radius: 5px;
  }
  @media only screen and (max-width: 900px) {
    .video_engine_div{
      height: 100vh;
      width: 100%;
    }
    .chat-div{
        position: absolute;
        top: 0px;
        right: -110%;
        opacity: 0;
        transition: all 0.5s ease;
    }
    .chat-div.active {
      right: 0px;
      opacity: 1;
      z-index: 1;
      width: 60%;
    }

    .wrap-div{
      width: 100vw;
      display: flex;
      position: relative;
    }
    /* .sidebar{
      display: block;
    } */
  }


  @media only screen and (max-width: 560px) {
    .chat-div2.active2 {
      width: 80%;
    }
  }