.modal_wraper{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgba(0,0,0,0.5); */
    position: relative;
}
.modal_div{
    width: 500px;
    height: 220px;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 40px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modal_div input{
    height: 50px;
    width: 100%;
    font-size: 17px;
    margin-top: 10px;
}

.button_active{
    margin-top: 10px;
    padding: 10px 18px;
    background-color: #0000ffb3;
    border: none;
    border-radius: 4px;
    color: white;
    transition:background-color .7s ease;
}
.button_inactive{
    background-color: #c9c9c9;
    margin-top: 10px;
    padding: 10px 18px;
    border: none;
    border-radius: 4px;
    color: white;
    transition:background-color .7s ease;
}

.side_logo{
    position: absolute;
    left: 20px;
    top: 20px;
    color: #777;
    font-size: 25px;
    font-weight: 400;
    
}
.modal_input{
    text-indent: 10px;
    /* border-radius: 5px; */
}
.modal_input:focus{
    outline: none !important;
    border-color: #0000ffb3;
}